/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState, useCallback } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Button from '@salesforce/design-system-react/components/button';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Spinner from '@salesforce/design-system-react/components/spinner';
import InventoryRequestIdCell from '../DataTableCells/InventoryRequestIdCell';
import ResetCell from '../DataTableCells/ResetCell';
import InventoryRequestStatusCell from '../DataTableCells/InventoryStatusCell';
import InventoryRequestDateTImeCell from '../DataTableCells/InventoryDateTImeCell';
import Inventory3PL from '../DataTableCells/Inventory3PL';
import '../index.scss';
import debounced from '../../../../util/debounced';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import SnackBar from '../../../Shared/SnackBar';
import InventoryRequestsAssignDataCell from '../DataTableCells/InventoryAssignDataCell';
import AccountCell from '../DataTableCells/AccountCell';
import { GET_ORDER_LIST } from '../../../../graphql/getOrderList';
import ASSIGN_INVENTORY_REQUEST from '../../../../graphql/mutations/assignInventoryRequest';
import { NEXT_DATA, PREV_DATA, NOTIFY_TYPE } from '../../../../util/constants';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import FilterTagReviewRequest from '../../../Shared/FilterTagReviewRequest';
import ReprocessQueueHeader from './ReprocessQueueHeader';
import ReprocessTypeCell from './ReprocessTypeCell';
import { GET_FILTER_PRESETS } from '../../../../graphql/getFilterPresets';
import ReprocessQueueFilter from './ReprocessQueueFilter';
import {
  setInventoryReprocessFilters,
  getInventoryReprocessFilters,
  clearInventoryReprocessFilters,
} from '../../../../store/ducks/inventoryReprocessFilters';

interface Props {
  action: string;
}

interface DynamicValue {
  [key: string]: any;
}
interface Filter {
  status: SelectedData[];
  salesReps: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  procedureType?: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  eventType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
}

interface FilterPreset {
  filterValues: Filter;
  filterExternalId?: string;
  filterName?: string;
}

interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}

interface InventoryTotalCostDataCellProps {
  item?: {
    totalcost?: string;
  };
}

interface Sort {
  sortColumn: string;
  sortColumnDirection: {
    [key: string]: string;
  };
}

const InventoryTotalCostDataCell: FC<InventoryTotalCostDataCellProps> = ({
  item = {
    totalcost: null,
  },
}) => {
  const { totalcost } = item;
  return (
    <DataTableCell title={totalcost || ''}>
      <p>{totalcost ? parseFloat(totalcost).toFixed(2) : '0.00'}</p>
    </DataTableCell>
  );
};

// CustomDataTableCell must have the same displayName as DataTableCell or it will be ignored.
InventoryRequestIdCell.displayName = DataTableCell.displayName;
InventoryRequestStatusCell.displayName = DataTableCell.displayName;
ReprocessTypeCell.displayName = DataTableCell.displayName;
InventoryRequestsAssignDataCell.displayName = DataTableCell.displayName;
AccountCell.displayName = DataTableCell.displayName;
InventoryRequestDateTImeCell.displayName = DataTableCell.displayName;
InventoryTotalCostDataCell.displayName = DataTableCell.displayName;
Inventory3PL.displayName = DataTableCell.displayName;
ResetCell.displayName = DataTableCell.displayName;

const ReprocessQueue: FC<Props> = ({ action }) => {
  const [getReprocessQueueList, { data: inventoryQueueData, refetch, loading }] = useLazyQuery(
    GET_ORDER_LIST,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [assignToOps, { data: assignRespone, loading: isLoading }] = useMutation(
    ASSIGN_INVENTORY_REQUEST
  );
  const [inventoryQueueList, setState] = useState([]);
  const { open, notification, openSnackBar } = useSnackBar();
  const [offsetRequestReview, setOffsetRequestReview] = useState(0);
  const [offsetRequestAssign, setOffsetRequestAssign] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState<object | null>(null);
  const userInfo = useSelector(getUserInfo);
  const [sortObj, setSortObj] = useState<Sort>();
  const [updatedSelectedFilter, setUpdatedSelectedFilter] = useState<
    FilterPreset | DynamicValue | null
  >(null);
  const [selectedFilter, setSelectedFilter] = useState<FilterPreset | DynamicValue | null>(null);
  const LIMIT = 100;
  useEffect(() => {
    getReprocessQueueList({
      variables: {
        limit: LIMIT,
        offset: 0,
        orderType: 'REPROCESS_QUEUE',
        screenType: 'FAILED',
        filters: appliedFilters,
      },
    });
  }, [action, getReprocessQueueList, appliedFilters]);
  const dispatch = useDispatch();
  const [openReturnFilter, setOpenReturnFilter] = useState(false);
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<any>();
  const [filterEventModalVisible, setFilterEventModalVisible] = useState(false);
  const [isEditFilter, setIsEditFilter] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const { data: filterPresets, refetch: refetchFilterPresets } = useQuery(GET_FILTER_PRESETS, {
    variables: {
      userId: userInfo?.id,
      type: 'Reprocess',
    },
  });
  const filterData = filterPresets?.getFilterPresets;

  useEffect(() => {
    if (assignRespone && assignRespone.assignInventoryRequest.message === NOTIFY_TYPE.SUCCESS) {
      openSnackBar(NOTIFY_TYPE.SUCCESS, 'Reset Assignment Successfully');
      refetch();
    }
  }, [assignRespone, refetch, openSnackBar]);

  useEffect(() => {
    if (inventoryQueueData && inventoryQueueData.getOrderList) {
      setState(inventoryQueueData.getOrderList);
    }
  }, [inventoryQueueData]);

  // This function fetches the next and previous records of the events list
  const handlePaginationButtonClicked = (type: string): void => {
    let finalOffset;
    if (action.toUpperCase() === 'ASSIGN') {
      if (type === NEXT_DATA) {
        finalOffset = offsetRequestReview + LIMIT;
      } else {
        finalOffset = offsetRequestReview - LIMIT;
      }
      const filters = appliedFilters || undefined;
      getReprocessQueueList({
        variables: {
          limit: LIMIT,
          offset: finalOffset,
          orderType: 'REPROCESS_QUEUE',
          screenType: 'FAILED',
          filters,
        },
      });
      setOffsetRequestAssign(finalOffset);
    }
  };

  const applyFilters = (appliedFilter: object | null): void => {
    setAppliedFilters(appliedFilter);
    setSelectedFilter(appliedFilter);
    setOffsetRequestAssign(0);
    setOffsetRequestReview(0);
    const filters = appliedFilter || undefined;
    setSelectedFilterApiValues(filters);
    getReprocessQueueList({
      variables: {
        limit: LIMIT,
        offset: 0,
        orderType: 'REPROCESS_QUEUE',
        screenType: 'FAILED',
        filters,
      },
    });
    dispatch(setInventoryReprocessFilters(JSON.stringify(appliedFilter)));
  };

  const setSearch = (searchedItem: string): void => {
    if (searchedItem.length >= 3) {
      getReprocessQueueList({
        variables: {
          limit: LIMIT,
          offset: 0,
          orderType: 'REPROCESS_QUEUE',
          screenType: 'FAILED',
          filters: {
            searchText: searchedItem,
          },
        },
      });
    } else {
      getReprocessQueueList({
        variables: {
          limit: LIMIT,
          offset: 0,
          orderType: 'REPROCESS_QUEUE',
          screenType: 'FAILED',
        },
      });
    }
  };

  const debouncedFetchItems = useCallback(debounced(500, setSearch), [debounced, setSearch]);

  const handleSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchItems(value);
      } else {
        debouncedFetchItems(value);
      }
    },
    [debouncedFetchItems]
  );

  const handleSort = (sortColumn: any): void => {
    const sortProperty =
      sortColumn.property === 'accountName' ? 'accountNumber' : sortColumn.property;
    const { sortDirection } = sortColumn;
    const sort = {
      sortColumn: sortProperty,
      sortColumnDirection: {
        [sortProperty]: sortDirection,
      },
    };
    if (sortProperty === 'diplayOrderType') {
      const withoutStatndardlist = inventoryQueueList.filter(
        (type: any) => type.diplayOrderType !== 'Inventory Return'
      );
      const withStandardlist = inventoryQueueList.filter(
        (type: any) => type.diplayOrderType === 'Inventory Return'
      );
      withoutStatndardlist.sort((a: any, b: any) => {
        // String Compare
        let val = 0;
        if (a[sortProperty] > b[sortProperty]) {
          val = 1;
        }
        if (a[sortProperty] < b[sortProperty]) {
          val = -1;
        }
        if (sortDirection === 'desc') {
          val *= -1;
        }
        return val;
      });
      let finalData: any = '';
      if (sortDirection === 'asc') {
        finalData = withoutStatndardlist.concat(withStandardlist);
      }
      if (sortDirection === 'desc') {
        finalData = withStandardlist.concat(withoutStatndardlist);
      }
      setState(finalData);
    } else {
      const list =
        inventoryQueueList &&
        inventoryQueueList.sort((a: any, b: any) => {
          // String Compare
          let val = 0;
          if (a[sortProperty] === null) {
            if (b[sortProperty] < '') {
              val = -1;
            }
            if (b[sortProperty] > '') {
              val = 1;
            }
            if (sortDirection === 'desc') {
              val *= -1;
            }
          } else if (b[sortProperty] === null) {
            if (a[sortProperty] > '') {
              val = -1;
            }
            if (a[sortProperty] < '') {
              val = 1;
            }
            if (sortDirection === 'desc') {
              val *= -1;
            }
          } else {
            if (a[sortProperty] > b[sortProperty]) {
              val = 1;
            }
            if (a[sortProperty] < b[sortProperty]) {
              val = -1;
            }
            if (sortDirection === 'desc') {
              val *= -1;
            }
          }
          return val;
        });
    }
    setSortObj(sort);
    // sortedcolumn(sortColumn);
  };

  const handleReset = (externalId: any): void => {
    assignToOps({
      variables: {
        externalId,
        assignedUser: '',
      },
    });
  };
  const filterStore = useSelector(getInventoryReprocessFilters);
  useEffect(() => {
    const setFilters = JSON.parse(filterStore || '{}');
    setAppliedFilters(setFilters);
    setSelectedFilter(setFilters);
    setSelectedFilterApiValues(setFilters);
  }, [filterStore]);

  const handleEditFilter = (editFilter: boolean): void => {
    setIsEditFilter(editFilter);
  };

  const handleEditPress = (filter: FilterPreset, isEditPress: boolean): void => {
    setSelectedFilter(filter);
    setFilterEventModalVisible(!filterEventModalVisible);
    setOpenFilter(!openFilter);
    if (isEditPress) {
      handleEditFilter(true);
    }
  };

  const resetFilters = useCallback((): void => {
    dispatch(clearInventoryReprocessFilters());
    setAppliedFilters(null);
    setSelectedFilter(null);
    setUpdatedSelectedFilter(null);
    setIsEditFilter(false);
    setSelectedFilterApiValues(null);
    getReprocessQueueList({
      variables: {
        limit: LIMIT,
        offset: 0,
        orderType: 'REPROCESS_QUEUE',
        screenType: 'FAILED',
      },
    });
  }, [dispatch, getReprocessQueueList]);

  const getFilterApiValue = (filterValues: DynamicValue): any => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          let values;
          values = filterValues[key].map((item: SelectedData) => item.id);
          if (key === 'region') {
            values = filterValues[key].map((item: SelectedData) => item.label);
          } else if (key === 'territory') {
            values = filterValues[key].map((item: SelectedData) => item.label);
          }
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
          if (key === 'threePL') {
            values = filterValues[key].map((item: SelectedData) => item.id)?.toString();
            reducedFilterValues = { ...reducedFilterValues, [key]: values };
          }
        } else if (Array.isArray(filterValues[key]) && filterValues[key]) {
          if (filterValues[key].length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterDataa = { ...reducedFilterValues };
      return filterDataa;
    }
    return {};
  };

  const handleFilterEvents = (
    filterValues: Filter | DynamicValue | null,
    filterName?: string
  ): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setUpdatedSelectedFilter(filter);
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
      applyFilters(filters);
    } else {
      resetFilters();
    }
  };

  const handleDeleteFilterPreset = (externalId: string): void => {
    const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;
    if (filterExternalId && filterExternalId === externalId) {
      resetFilters();
    }
  };

  const handleToggleFilterEvent = (): void => {
    handleEditFilter(false);
    setSelectedFilter(updatedSelectedFilter);
    setFilterEventModalVisible(!filterEventModalVisible);
    setOpenFilter(!openFilter);
  };

  const handleToggleFilter = (): void => {
    setOpenFilter(!openFilter);
  };

  return (
    <IconSettings iconPath="/icons">
      <ReprocessQueueHeader
        handleToggleFilter={handleToggleFilter}
        handleSearch={handleSearch}
        filterPresets={filterData}
        resetFilters={resetFilters}
        onEditPress={handleEditPress}
        handleFilterEvents={handleFilterEvents}
        selectedFilterApiValues={selectedFilterApiValues}
        totalFilterResult={inventoryQueueList.length}
      />
      <SnackBar open={open} notification={notification} />
      {appliedFilters && !isEditFilter && (
        <FilterTagReviewRequest selectedFilter={appliedFilters} handleFilter={applyFilters} />
      )}
      <div style={{ overflow: 'auto' }}>
        {!inventoryQueueData || loading || isLoading ? (
          <Spinner size="small" variant="base" assistiveText={{ label: 'Loading...' }} />
        ) : (
          <DataTable
            items={inventoryQueueList || []}
            id="DataTableExample-1-default"
            className="font-12"
            // fixedHeader
            joined
            assistiveText={{
              columnSort: 'sort this column',
              columnSortedAscending: 'asc',
              columnSortedDescending: 'desc',
            }}
            fixedLayout
            keyboardNavigation
            onSort={handleSort}
          >
            {[
              <DataTableColumn
                key="createdDate"
                label="Submit Date"
                property="createdDate"
                // width="120px"
                isSorted={sortObj?.sortColumn === 'createdDate'}
                sortable
              >
                <InventoryRequestDateTImeCell dateFor="created" />
              </DataTableColumn>,
              <DataTableColumn
                key="eventId"
                label="Record"
                isSorted={sortObj?.sortColumn === 'eventId'}
                sortable
                property="eventId"
              >
                <InventoryRequestIdCell action={action} />
              </DataTableColumn>,
              <DataTableColumn
                key="diplayOrderType"
                label="Type"
                property="diplayOrderType"
                isSorted={sortObj?.sortColumn === 'diplayOrderType'}
                sortable
              >
                <ReprocessTypeCell />
              </DataTableColumn>,
              <DataTableColumn
                key="status"
                label="Status"
                property="status"
                isSorted={sortObj?.sortColumn === 'status'}
                sortable
              >
                <InventoryRequestStatusCell />
              </DataTableColumn>,
              <DataTableColumn
                key="failureReason"
                label="Failure Reason"
                property="failureReason"
                isSorted={sortObj?.sortColumn === 'failureReason'}
                sortable
              />,
              <DataTableColumn
                key="accountName"
                label="Account"
                property="accountName"
                isSorted={sortObj?.sortColumn === 'accountName'}
                sortable
              >
                <AccountCell />
              </DataTableColumn>,
              <DataTableColumn
                key="salesRepName"
                label="Sales Rep"
                property="salesRepName"
                isSorted={sortObj?.sortColumn === 'salesRep'}
                sortable
              />,
              <DataTableColumn
                key="territoryNumber"
                label="Territory"
                property="territoryNumber"
                isSorted={sortObj?.sortColumn === 'territoryNumber'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.territoryNumber}
              />,
              <DataTableColumn
                key="serviceLevel"
                label="Service Level"
                property="serviceLevel"
                isSorted={sortObj?.sortColumn === 'serviceLevel'}
                sortable
              />,
              <DataTableColumn
                key="needByDate"
                label="Need by Date"
                property="needByDate"
                isSorted={sortObj?.sortColumn === 'needByDate'}
                sortable
              >
                <InventoryRequestDateTImeCell />
              </DataTableColumn>,
              <DataTableColumn
                key="assignedUserName"
                label="Assign To"
                width="160px"
                property="assignedUserName"
                isSorted={sortObj?.sortColumn === 'assignedUserName'}
                sortable
              >
                <InventoryRequestsAssignDataCell
                  // action={action}
                  openSnackBar={openSnackBar}
                  refetch={refetch}
                />
              </DataTableColumn>,
              <DataTableColumn key="assigned" label="" width="50px">
                <ResetCell handleReset={handleReset} />
              </DataTableColumn>,
            ]}
          </DataTable>
        )}
        {inventoryQueueList && inventoryQueueList.length > 0 && action.toUpperCase() === 'ASSIGN' && (
          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronleft"
                iconSize="small"
                disabled={!(offsetRequestAssign > 0)}
                iconVariant="bare"
                // will fetch the previous events data
                onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                variant="icon"
              />
            </div>
            <div className="slds-col slds-p-left_medium slds-p-right_medium">
              <span>{`${offsetRequestAssign + 1} - ${offsetRequestAssign + LIMIT}`}</span>
            </div>
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={inventoryQueueList && inventoryQueueList.length < 100}
                // will fetch the next events data
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            </div>
          </div>
        )}
      </div>
      {openFilter && (
        <ReprocessQueueFilter
          openFilter={openFilter}
          handleToggleFilter={handleToggleFilter}
          applyFilters={applyFilters}
          selectedFilter={selectedFilter}
          handleToggleFilterEvent={handleToggleFilterEvent}
          handleFilterEvents={handleFilterEvents}
          handleEditFilter={handleEditFilter}
          refetchFilterPresets={refetchFilterPresets}
          isEditFilter={isEditFilter}
          handleClearFilter={handleDeleteFilterPreset}
        />
      )}
    </IconSettings>
  );
};

export default ReprocessQueue;
