/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState, useCallback } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Button from '@salesforce/design-system-react/components/button';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import Spinner from '@salesforce/design-system-react/components/spinner';
import InventoryRequestIdCell from './DataTableCells/InventoryRequestIdCell';
import ResetCell from './DataTableCells/ResetCell';
import InventoryRequestStatusCell from './DataTableCells/InventoryStatusCell';
import InventoryRequestDateTImeCell from './DataTableCells/InventoryDateTImeCell';
import Inventory3PL from './DataTableCells/Inventory3PL';
import './index.scss';
import InventoryQueueHeader from './InventoryQueueHeader';
import debounced from '../../../util/debounced';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import SnackBar from '../../Shared/SnackBar';
import InventoryRequestsAssignDataCell from './DataTableCells/InventoryAssignDataCell';
import AccountCell from './DataTableCells/AccountCell';
import { GET_INVENTORY_REVIEW_LIST } from '../../../graphql/getInventoryReviewList';
import ASSIGN_INVENTORY_REQUEST from '../../../graphql/mutations/assignInventoryRequest';
import { NEXT_DATA, PREV_DATA, NOTIFY_TYPE } from '../../../util/constants';
import { getUSDString } from '../../../util/utilityFunctions';
import { getUserInfo } from '../../../store/ducks/userInfo';
import FilterTagReviewRequest from '../../Shared/FilterTagReviewRequest';
import { GET_FILTER_PRESETS } from '../../../graphql/getFilterPresets';
import InventoryQueueFilter from './InventoryQueueFilter';
import {
  setInventoryReviewFilters,
  getInventoryReviewFilters,
  clearInventoryReviewFilters,
} from '../../../store/ducks/inventoryReviewFilters';

interface Props {
  action: string;
}

interface InventoryTotalCostDataCellProps {
  item?: {
    totalcost?: string;
  };
}

interface Sort {
  sortColumn: string;
  sortColumnDirection: {
    [key: string]: string;
  };
}

interface SelectedData {
  id: string;
  label: string;
  __typename?: string;
}

interface Filter {
  status: SelectedData[];
  salesReps: SelectedData[];
  coveringReps: SelectedData[];
  account: SelectedData[];
  branch: SelectedData[];
  franchise: SelectedData[];
  surgeon: SelectedData[];
  procedureDetail: SelectedData[];
  procedureType?: SelectedData[];
  startDate: Date | string;
  endDate: Date | string;
  usage: string;
  eventType: string[];
  filterExternalId?: string;
  filterName?: string;
  search?: string;
}

interface FilterPreset {
  filterValues: Filter;
  filterExternalId?: string;
  filterName?: string;
}

interface DynamicValue {
  [key: string]: any;
}

const InventoryTotalCostDataCell: FC<InventoryTotalCostDataCellProps> = ({
  item = {
    totalcost: null,
  },
}) => {
  const { totalcost } = item;
  return (
    <DataTableCell title={totalcost || ''}>
      <p>{totalcost ? getUSDString(totalcost.toString()) : '0.00'}</p>
    </DataTableCell>
  );
};

// CustomDataTableCell must have the same displayName as DataTableCell or it will be ignored.
InventoryRequestIdCell.displayName = DataTableCell.displayName;
InventoryRequestStatusCell.displayName = DataTableCell.displayName;
InventoryRequestsAssignDataCell.displayName = DataTableCell.displayName;
AccountCell.displayName = DataTableCell.displayName;
InventoryRequestDateTImeCell.displayName = DataTableCell.displayName;
InventoryTotalCostDataCell.displayName = DataTableCell.displayName;
Inventory3PL.displayName = DataTableCell.displayName;
ResetCell.displayName = DataTableCell.displayName;

const InventoryQueue: FC<Props> = ({ action }) => {
  const [getQueueList, { data: inventoryQueueData, refetch, loading }] = useLazyQuery(
    GET_INVENTORY_REVIEW_LIST,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [assignToOps, { data: assignRespone, loading: isLoading }] = useMutation(
    ASSIGN_INVENTORY_REQUEST
  );
  const [inventoryQueueList, setState] = useState([]);
  const { open, notification, openSnackBar } = useSnackBar();
  const [offsetRequestReview, setOffsetRequestReview] = useState(0);
  const [offsetRequestAssign, setOffsetRequestAssign] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState<object | null>(null);
  const userInfo = useSelector(getUserInfo);
  const [sortObj, setSortObj] = useState<Sort>();
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState(null);
  const [filterEventModalVisible, setFilterEventModalVisible] = useState(false);
  const [isEditFilter, setIsEditFilter] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [updatedSelectedFilter, setUpdatedSelectedFilter] = useState<
    FilterPreset | DynamicValue | null
  >(null);
  const [selectedFilter, setSelectedFilter] = useState<FilterPreset | DynamicValue | null>(null);
  const dispatch = useDispatch();
  const LIMIT = 100;
  useEffect(() => {
    getQueueList({
      variables: {
        limit: LIMIT,
        offset: 0,
        type: 'REVIEW',
        filters: appliedFilters,
      },
    });
  }, [action, getQueueList, appliedFilters]);

  const { data: filterPresets, refetch: refetchFilterPresets } = useQuery(GET_FILTER_PRESETS, {
    variables: {
      userId: userInfo?.id,
      type: 'Review Request',
    },
  });

  const filterData = filterPresets?.getFilterPresets;

  useEffect(() => {
    if (assignRespone && assignRespone.assignInventoryRequest.message === NOTIFY_TYPE.SUCCESS) {
      openSnackBar(NOTIFY_TYPE.SUCCESS, 'Reset Assignment Successfully');
      refetch();
    }
  }, [assignRespone, refetch, openSnackBar]);

  useEffect(() => {
    if (inventoryQueueData && inventoryQueueData.getInventoryReviewList) {
      setState(inventoryQueueData.getInventoryReviewList);
    }
  }, [inventoryQueueData]);

  // This function fetches the next and previous records of the events list
  const handlePaginationButtonClicked = (type: string): void => {
    let finalOffset;
    if (action.toUpperCase() === 'REVIEW') {
      if (type === NEXT_DATA) {
        finalOffset = offsetRequestReview + LIMIT;
      } else {
        finalOffset = offsetRequestReview - LIMIT;
      }
      const filters = appliedFilters || undefined;
      getQueueList({
        variables: {
          limit: LIMIT,
          offset: finalOffset,
          type: 'REVIEW',
          filters,
        },
      });
      setOffsetRequestReview(finalOffset);
    } else if (action.toUpperCase() === 'ASSIGN') {
      if (type === NEXT_DATA) {
        finalOffset = offsetRequestReview + LIMIT;
      } else {
        finalOffset = offsetRequestReview - LIMIT;
      }
      const filters = appliedFilters || undefined;
      getQueueList({
        variables: {
          limit: LIMIT,
          offset: finalOffset,
          type: 'REVIEW',
          filters,
        },
      });
      setOffsetRequestAssign(finalOffset);
    }
  };

  const applyFilters = (appliedFilter: any): void => {
    setAppliedFilters(appliedFilter);
    setSelectedFilter(appliedFilter);
    setOffsetRequestAssign(0);
    setOffsetRequestReview(0);
    const filters = appliedFilter || undefined;
    setSelectedFilterApiValues(filters);
    getQueueList({
      variables: {
        limit: LIMIT,
        offset: 0,
        type: 'REVIEW',
        filters,
      },
    });
    dispatch(setInventoryReviewFilters(JSON.stringify(appliedFilter)));
  };

  const setSearch = (searchedItem: string): void => {
    if (searchedItem.length >= 3) {
      getQueueList({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: 'REVIEW',
          filters: {
            searchText: searchedItem,
          },
        },
      });
    } else {
      getQueueList({
        variables: {
          limit: LIMIT,
          offset: 0,
          type: action.toUpperCase(),
        },
      });
    }
  };

  const debouncedFetchItems = useCallback(debounced(500, setSearch), [debounced, setSearch]);

  const handleSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      if (value && value.length >= 3) {
        debouncedFetchItems(value);
      } else {
        debouncedFetchItems(value);
      }
    },
    [debouncedFetchItems]
  );

  const handleSort = (sortColumn: any): void => {
    const sortProperty =
      sortColumn.property === 'accountName' ? 'accountNumber' : sortColumn.property;
    const { sortDirection } = sortColumn;
    const sort = {
      sortColumn: sortProperty,
      sortColumnDirection: {
        [sortProperty]: sortDirection,
      },
    };
    const list =
      inventoryQueueList &&
      inventoryQueueList.sort((a: any, b: any) => {
        // if (sortProperty === 'totalAmount') {
        //   // Number Compare
        //   if (sortDirection === 'desc') {
        //     return Number(b[sortProperty]) - Number(a[sortProperty]);
        //   }
        //   return Number(a[sortProperty]) - Number(b[sortProperty]);
        // }
        // if (sortProperty === 'createdDate' || sortProperty === 'returnExpiryDate') {
        //   // Date Compare
        //   if (sortDirection === 'desc') {
        //     return Number(new Date(b[sortProperty])) - Number(new Date(a[sortProperty]));
        //   }
        //   return Number(new Date(a[sortProperty])) - Number(new Date(b[sortProperty]));
        // }
        // String Compare
        let val = 0;
        if (a[sortProperty] === null) {
          if (b[sortProperty] < '') {
            val = -1;
          }
          if (b[sortProperty] > '') {
            val = 1;
          }
          if (sortDirection === 'desc') {
            val *= -1;
          }
        } else if (b[sortProperty] === null) {
          if (a[sortProperty] > '') {
            val = -1;
          }
          if (a[sortProperty] < '') {
            val = 1;
          }
          if (sortDirection === 'desc') {
            val *= -1;
          }
        } else {
          if (a[sortProperty] > b[sortProperty]) {
            val = 1;
          }
          if (a[sortProperty] < b[sortProperty]) {
            val = -1;
          }
          if (sortDirection === 'desc') {
            val *= -1;
          }
        }
        return val;
      });
    setSortObj(sort);
    // sortedcolumn(sortColumn);
  };

  const handleReset = (externalId: any): void => {
    assignToOps({
      variables: {
        externalId,
        assignedUser: '',
      },
    });
  };

  const resetFilters = useCallback((): void => {
    dispatch(clearInventoryReviewFilters());
    setAppliedFilters(null);
    setSelectedFilter(null);
    setUpdatedSelectedFilter(null);
    setIsEditFilter(false);
    setSelectedFilterApiValues(null);
    getQueueList({
      variables: {
        limit: LIMIT,
        offset: 0,
        type: 'REVIEW',
      },
    });
  }, [dispatch, getQueueList]);

  const handleEditFilter = (editFilter: boolean): void => {
    setIsEditFilter(editFilter);
  };

  const handleToggleFilterEvent = (): void => {
    handleEditFilter(false);
    setSelectedFilter(updatedSelectedFilter);
    setFilterEventModalVisible(!filterEventModalVisible);
    setOpenFilter(!openFilter);
  };

  const getFilterApiValue = (filterValues: DynamicValue): any => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          let values;
          values = filterValues[key].map((item: SelectedData) => item.id);
          if (key === 'region') {
            values = filterValues[key].map((item: SelectedData) => item.label);
          } else if (key === 'territory') {
            values = filterValues[key].map((item: SelectedData) => item.label);
          }
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (Array.isArray(filterValues[key]) && filterValues[key]) {
          if (filterValues[key].length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filtersData = { ...reducedFilterValues };
      return filtersData;
    }
    return {};
  };

  const handleRefetchItems = (filter: any): void => {
    getQueueList({
      variables: {
        limit: LIMIT,
        offset: 0,
        type: 'REVIEW',
        filters: filter,
      },
    });
  };

  const handleFilterEvents = (
    filterValues: Filter | DynamicValue | null,
    filterName?: string
  ): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setUpdatedSelectedFilter(filter);
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
      applyFilters(filters);
    } else {
      resetFilters();
    }
  };

  const handleEditPress = (filter: FilterPreset, isEditPress: boolean): void => {
    setSelectedFilter(filter);
    setFilterEventModalVisible(!filterEventModalVisible);
    setOpenFilter(!openFilter);
    if (isEditPress) {
      handleEditFilter(true);
    }
  };

  const handleDeleteFilterPreset = (externalId: string): void => {
    const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;
    if (filterExternalId && filterExternalId === externalId) {
      resetFilters();
    }
  };

  const handleToggleFilter = (): void => {
    setOpenFilter(!openFilter);
  };
  const filterStore = useSelector(getInventoryReviewFilters);
  useEffect(() => {
    const setFilters = JSON.parse(filterStore || '{}');
    setAppliedFilters(setFilters);
    setSelectedFilter(setFilters);
    setSelectedFilterApiValues(setFilters);
  }, [filterStore]);

  return (
    <IconSettings iconPath="/icons">
      <InventoryQueueHeader
        handleToggleFilter={handleToggleFilter}
        handleSearch={handleSearch}
        filterPresets={filterData}
        resetFilters={resetFilters}
        onEditPress={handleEditPress}
        handleFilterEvents={handleFilterEvents}
        selectedFilterApiValues={selectedFilterApiValues}
        totalFilterResult={inventoryQueueList.length}
      />
      <SnackBar open={open} notification={notification} />
      {appliedFilters && !isEditFilter && (
        <FilterTagReviewRequest selectedFilter={appliedFilters} handleFilter={applyFilters} />
      )}
      <div style={{ overflow: 'auto' }}>
        {!inventoryQueueData || loading || isLoading ? (
          <Spinner size="small" variant="base" assistiveText={{ label: 'Loading...' }} />
        ) : (
          <DataTable
            items={inventoryQueueList || []}
            id="DataTableExample-1-default"
            className="font-12"
            // fixedHeader
            joined
            assistiveText={{
              columnSort: 'sort this column',
              columnSortedAscending: 'asc',
              columnSortedDescending: 'desc',
            }}
            fixedLayout
            keyboardNavigation
            onSort={handleSort}
          >
            {[
              <DataTableColumn
                key="submittedDate"
                label="Submit Date"
                property="submittedDate"
                // width="120px"
                isSorted={sortObj?.sortColumn === 'submittedDate'}
                sortable
              >
                <InventoryRequestDateTImeCell dateFor="submitted" />
              </DataTableColumn>,
              <DataTableColumn
                key="eventId"
                label="Record"
                isSorted={sortObj?.sortColumn === 'eventId'}
                sortable
                property="eventId"
              >
                <InventoryRequestIdCell action={action} />
              </DataTableColumn>,
              <DataTableColumn
                key="type"
                label="Type"
                property="type"
                isSorted={sortObj?.sortColumn === 'type'}
                sortable
              />,
              <DataTableColumn
                key="eventStatus"
                label="Status"
                property="eventStatus"
                isSorted={sortObj?.sortColumn === 'eventStatus'}
                sortable
              >
                <InventoryRequestStatusCell />
              </DataTableColumn>,
              <DataTableColumn
                key="accountName"
                label="Account"
                property="accountName"
                isSorted={sortObj?.sortColumn === 'accountName'}
                sortable
              >
                <AccountCell />
              </DataTableColumn>,
              <DataTableColumn
                key="shippingPostalcode"
                label="Zip Code"
                property="shippingPostalcode"
                isSorted={sortObj?.sortColumn === 'shippingPostalcode'}
                sortable
              />,
              <DataTableColumn
                key="salesRep"
                label="Sales Rep"
                property="salesRep"
                isSorted={sortObj?.sortColumn === 'salesRep'}
                sortable
              />,
              <DataTableColumn
                key="territoryNumber"
                label="Territory"
                property="territoryNumber"
                isSorted={sortObj?.sortColumn === 'territoryNumber'}
                sortable
                sortDirection={sortObj?.sortColumnDirection.territoryNumber}
              />,
              <DataTableColumn
                key="serviceLevel"
                label="Service Level"
                property="serviceLevel"
                isSorted={sortObj?.sortColumn === 'serviceLevel'}
                sortable
              />,
              <DataTableColumn
                key="needByDate"
                label="Need by Date"
                property="needByDate"
                isSorted={sortObj?.sortColumn === 'needByDate'}
                sortable
              >
                <InventoryRequestDateTImeCell dateFor="neededBy" />
              </DataTableColumn>,
              <DataTableColumn
                key="assignedUserName"
                label="Assign To"
                width="160px"
                property="assignedUserName"
                isSorted={sortObj?.sortColumn === 'assignedUserName'}
                sortable
              >
                <InventoryRequestsAssignDataCell
                  // action={action}
                  openSnackBar={openSnackBar}
                  refetch={refetch}
                />
              </DataTableColumn>,
              <DataTableColumn key="assigned" label="" width="50px">
                <ResetCell handleReset={handleReset} />
              </DataTableColumn>,
            ]}
          </DataTable>
        )}
        {inventoryQueueList && inventoryQueueList.length > 0 && action.toUpperCase() === 'ASSIGN' && (
          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronleft"
                iconSize="small"
                disabled={!(offsetRequestAssign > 0)}
                iconVariant="bare"
                // will fetch the previous events data
                onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                variant="icon"
              />
            </div>
            <div className="slds-col slds-p-left_medium slds-p-right_medium">
              <span>{`${offsetRequestAssign + 1} - ${offsetRequestAssign + LIMIT}`}</span>
            </div>
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={inventoryQueueList && inventoryQueueList.length < 100}
                // will fetch the next events data
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            </div>
          </div>
        )}
        {inventoryQueueList && inventoryQueueList.length > 0 && action.toUpperCase() === 'REVIEW' && (
          <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronleft"
                iconSize="small"
                disabled={!(offsetRequestReview > 0)}
                iconVariant="bare"
                // will fetch the previous events data
                onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                variant="icon"
              />
            </div>
            <div className="slds-col slds-p-left_medium slds-p-right_medium">
              <span>{`${offsetRequestReview + 1} - ${offsetRequestReview + LIMIT}`}</span>
            </div>
            <div className="slds-col">
              <Button
                assistiveText={{ icon: 'Icon Bare Small' }}
                iconCategory="utility"
                iconName="chevronright"
                iconSize="small"
                iconVariant="bare"
                disabled={inventoryQueueList && inventoryQueueList.length < 100}
                // will fetch the next events data
                onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                variant="icon"
              />
            </div>
          </div>
        )}
      </div>
      {openFilter && (
        <InventoryQueueFilter
          openFilter={openFilter}
          handleToggleFilter={handleToggleFilter}
          applyFilters={applyFilters}
          selectedFilter={selectedFilter}
          handleToggleFilterEvent={handleToggleFilterEvent}
          handleFilterEvents={handleFilterEvents}
          handleEditFilter={handleEditFilter}
          refetchFilterPresets={refetchFilterPresets}
          isEditFilter={isEditFilter}
          handleClearFilter={handleDeleteFilterPreset}
        />
      )}
    </IconSettings>
  );
};

export default InventoryQueue;
